import { Component, Vue, Watch } from "vue-property-decorator";
import { GET_CHILDREN_LIST_BY_ID, GET_PROFILE_AGENT } from "../../../../api/account"
import { GET_CREDIT_HISTORY, WITHDRAWAL, DEPOSIT } from "../../../../api/payment"
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'

import 'vue2-datepicker/index.css';

Vue.use(VModal, { componentName: 'modal' })
@Component({
    components: {
        DatePicker
    },
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        sCurrency(value: any) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
})
default class Transfer extends Vue {
    $axios: any;
    private transferData: any = []
    private model: Model;
    private cookieData: any = Vue.$cookies.get("login")
    public dateFilter = {
        startDate: moment().local().startOf('day').toDate(),
        endDate: moment().local().endOf('day').toDate()
    }
    private credit = 0
    public detailLists: any = []
    private paginate = {
        page: 1,
        limit: 10
    }
    private currency = {}
    private pageSize = 0;
    private paginateShow = false;
    private dataLoading = false;
    private mainUser = true
    private payment = {
        id: "",
        amount: ""
    }
    private subAccountMenus = {
        credit: false
    }

    private role = this.$store.state.profile.roles[0] 

    @Watch('payment.amount')
    onPropertyChanged(value: string) {
        this.payment.amount = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }


    constructor() {
        super();
        this.model = new Model();
    }

    public onlyNumber(event: any) {
        const keyCode = (event.keyCode ? event.keyCode : event.which);
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
            event.preventDefault();
        }
    }

    async mounted() {

        if (Object.prototype.hasOwnProperty.call(this.cookieData, 'mainUsername')) {
            this.mainUser = false
            this.$store.state.profile.subAccountMenus.filter(val => {
                if (val.name == 'MENU_CREDIT') {
                    if (val.permission == 'readonly') {
                        this.subAccountMenus.credit = false
                    } else if (val.permission == 'editable') {
                        this.subAccountMenus.credit = true
                    }
                }
            });
        } else {
            this.mainUser = true
        }

        this.addRouteName()
        this.dataLoading = true
        await this.getProfile(this.cookieData.realUsername)
        await this.getTransferDataById(this.cookieData.realUsername, this.paginate.page, this.paginate.limit)
        this.dataLoading = false
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    private async getProfile(username: string) {
        const result = await GET_PROFILE_AGENT(username)
            this.credit = result[0].creditLimit
    }

    public async clickCallback(number: number) {
        this.paginate.page = number
        await this.getTransferDataById(this.cookieData.realUsername, this.paginate.page, this.paginate.limit)
    }

    public async openModal(id: string, field: string, data?: any) {
        switch (field) {
            case 'currency':
                this.payment.id = id
                this.currency = {}
                if (typeof data != 'undefined') {
                    data.products.forEach(val => {
                        Object.keys(val.rate).forEach(key => {
                            this.currency[key] = val.rate[key];
                        });
                    });

                    this.currency = Object.keys(this.currency).reduce((r, a) => {
                        r[a] = [...r[a] || [], a];
                        return r;
                    }, {});
                } else {
                    this.currency = { THB: [] }
                }
                this.$modal.show('modalCurrency');
                break;
            case 'deposit':
                this.payment.id = id
                this.payment.amount = ""
                this.$modal.show('modalDeposit');
                break;
            case 'withdrawal':
                this.payment.id = id
                this.payment.amount = ""
                this.$modal.show('modalWithdrawal');
                break;
            case 'detail':
                this.detailLists = []
                this.payment.id = id
                this.dateFilter = {
                    startDate: moment().local().startOf('day').toDate(),
                    endDate: moment().local().endOf('day').toDate()
                }
                this.$modal.show('modalPaymentDetail');
                await this.getDetailData(id)
                break;
        }

    }

    private async filterTransferData() {
        await this.getDetailData(this.payment.id)
    }

    private async getDetailData(id: string) {
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        const result = await GET_CREDIT_HISTORY(id, (this.dateFilter.startDate).toISOString(), (this.dateFilter.endDate).toISOString());
        if (result.success) {
            this.detailLists = result.data
        } else {
            this.detailLists = []
        }
        loader.hide()
    }

    public async save(field: string) {
        const amount = parseFloat(this.payment.amount.replace(/,/g, ''));
        const body = {
            parentId: this.cookieData.realUsername,
            id: this.payment.id,
            amount: amount
        }
        if (field == 'deposit') {
            if (amount > this.credit) {
                this.$swal({
                    text: "ยอดเงินไม่เพียงพอ",
                    icon: "error",
                    confirmButtonColor: '#CFA137',
                    confirmButtonText: "<span style='color: white;'>OK</span>"
                });
                return
            } else {
                const result = await DEPOSIT(body);
                if (result.success) {
                    this.$swal({
                        text: "บันทึกการฝากสำเร็จ",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false,
                        timerProgressBar: true,
                        onOpen: () => {
                            this.$swal.isLoading()
                        },
                        onClose: async () => {
                            location.reload();
                        }
                    })
                } else {
                    this.$swal({
                        text: result.error.message,
                        icon: "error",
                        timer: 1000,
                        showConfirmButton: false,
                        timerProgressBar: true,
                        onOpen: () => {
                            this.$swal.isLoading()
                        },
                        onClose: async () => {
                            this.$modal.hide('modalDeposit');
                        }
                    })
                }
            }

        } else if (field == 'withdrawal') {
            const result = await WITHDRAWAL(body);
            if (result.success) {
                this.$swal({
                    text: "บันทึกการถอนสำเร็จ",
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    onOpen: () => {
                        this.$swal.isLoading()
                    },
                    onClose: async () => {
                        location.reload();
                    }
                })
            } else {
                this.$swal({
                    text: result.error.message,
                    icon: "error",
                    timer: 1000,
                    showConfirmButton: false,
                    timerProgressBar: true,
                    onOpen: () => {
                        this.$swal.isLoading()
                    },
                    onClose: async () => {
                        this.$modal.hide('modalWithdrawal');
                    }
                })
            }
        }
    }

    private async getTransferDataById(user: string, page: number, limit: number) {
        let lists = []
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        lists = await GET_CHILDREN_LIST_BY_ID(user, page, limit)
        this.transferData = lists[0];
        if (this.transferData.metadata.length >= 1) {
            const num = (this.paginate.page * this.paginate.limit)
            for (let i = 0, k = num; i < this.transferData.metadata.length; i++, k++) {
                this.transferData.metadata[i].no = k - this.paginate.limit
            }
            if (this.transferData.pageInfo[0].count > this.paginate.limit) {
                this.paginateShow = true
            }
            this.pageSize = this.transferData.pageInfo[0].size;
        } else {
            this.transferData = []
            this.paginateShow = false
        }
        loader.hide()
    }

    public hide(field: string) {
        switch (field) {
            case 'currency':
                this.$modal.hide('modalCurrency');
                break;
            case 'deposit':
                this.$modal.hide('modalDeposit');
                break;
            case 'withdrawal':
                this.$modal.hide('modalWithdrawal');
                break;
            case 'detail':
                this.$modal.hide('modalPaymentDetail');
                break;
        }

    }
}

export default Transfer