











































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import TransferComponent from "./Transfer.component";

@Component
export default class Transfer extends Mixins(TransferComponent) {}
